/* add loading state */
function adowlSetLoadingState(){ $('.adowl-default').addClass('adowl-loading'); }

function owl_noValidateSubmit(sStep){
    $('#adowl-system-step').attr('value', sStep);
    $('#adowl-system-validator').remove();
    $('.adowl-save-progress').unbind('submit');
    adowlSetLoadingState();
    $('.adowl-save-progress').submit();
}

jQuery(document).ready(function($){
    /* make sure user session is passed around */
    var adowlSession = Cookies.get('PHPSESSID');
    if( adowlSession ){
        $('.adowl-session-action').each(function(){
            var postUrl = $(this).attr('action');
            $(this).attr('action', postUrl+'?PHPSESSID='+adowlSession);
        });
    }
    /* get active cat id */
    $iCategory = $('#adowl-step-form').data('adowl-active-category');
    
    /* main form validator and submit action */
    $('.adowl-save-progress').validate({
        errorClass: 'text-danger adowl-form-error',
        submitHandler: function(form) {
            $('.adowl-save-progress').find('input[type=submit]').attr('disabled','disabled');
            adowlSetLoadingState();
            form.submit();
          }
    });
    
    /* upgrade package */
    $('a[data-upgrade]').on('click', function(e){
        e.preventDefault();
        var iNewPackage = $(this).data('upgrade');
        $('#adowl-upgradable-package-id').attr('value', iNewPackage);
        owl_noValidateSubmit('build');
    });
               
    /* save event. This is different so not routed through validation. */
    $('.adowl-save-package').on('submit', function(e){
        adowlSetLoadingState();
        return true;
    });
    
    /* package upsells */
    $('input[data-upsell-id]').on('change', function(e){
        var iUpsellId = $(this).data('upsell-id');
        var oUpsellOptions = $('#adowl-upsell-'+iUpsellId+'-options');
        if( oUpsellOptions.length > 0 ){
            if( $(this).is(':checked') ) {
                $(oUpsellOptions).slideDown();
            } else {
                $(oUpsellOptions).slideUp();
            }
        }
    });
    
    /* package upsell refreshes */
    $('input[data-upsell-refresh]').on('change', function(){
        var sType = $(this).data('upsell-refresh');
        if(sType === 'uncheck'){
            if( $(this).not(':checked') ) {
                owl_noValidateSubmit('build');
            }
        } else if(sType === 'changed'){
            owl_noValidateSubmit('build');
        }
    });

    /* stock image */
    $('div[data-stock-image]').on('click', function(e){
        e.preventDefault();
        var iParent = $(this).data('stock-parent');
        $('#upsell-stock-'+iParent).val($(this).data('stock-image'));
        $('#adowl-upsell-'+iParent+'-options .active').removeClass('active');
        $(this).parent().addClass('active');
        owl_noValidateSubmit('build');
    });

    /* file remove change */
    $('.adowl-file-remove').change(function(){
        owl_noValidateSubmit($(this).data('step'));
    });
    
    /* change step */
    $('a[data-adowl-change-step]').on('click', function(e){
        e.preventDefault();
        adowlSetLoadingState();
        $('#adowl-step-change').val($(this).data('adowl-change-step'));
        $('#adowl-step-form').submit();
    });

    /* category toggle and set id's globally */
    function adowlToggleCategory(o){
        /* get category data */
        $iCategory = $(o).data('adowl-category');
        $iParent = $(o).data('adowl-parent');
        /* remove selected state classes */
        $('.tnt-check-square').attr('data-icon', 'square-full');
        $('.adowl-parent-categories .active').removeClass('active'); 
        /* set category selected state */
        $('.adowl-category-'+$iCategory+'-toggle').addClass('active').find('.fa-nest').replaceWith('<span class="fa-nest"><i class="fas tnt-check-square"></i></span>');
        /* set parent category selected state */
        if($iParent) $('.adowl-category-'+$iParent+'-toggle').addClass('active').find('.fa-nest').replaceWith('<span class="fa-nest"><i class="fas tnt-check-square"></i></span>');
        /* set skip categories to parent */
        if( $iCategory.toString().match(/skip/g) ) $iCategory = $iParent;
    }

    /* category select */
    $('a[data-adowl-category]').on('click', function(e){
        e.preventDefault();
        /* pause categories with children */
        var bPauseSubmit = $(this).data('adowl-pause-submit');
        /* toggle state */
        adowlToggleCategory(this);
        /* update category and save */
        if( !bPauseSubmit ){
            $('#adowl-category-id').val($iCategory);
            $('.adowl-save-package').submit();
        }
    });
    
    /* let's set selected state on package step when category is set */
    if( $('#adowl-category-id').length > 0 && $iCategory ){
        /* make sure parent is expanded */
        if( !$('.adowl-category-'+$iCategory+'-toggle').data('parent') ){
            $('.adowl-category-'+$iCategory+'-toggle').parent().addClass('in');
        }
        adowlToggleCategory( $('a[data-adowl-category='+$iCategory+']') );
    }
    
    /* package select */
    $('div[data-adowl-select-package]').on('click', function(e){
        e.preventDefault();
        /* pause categories with children */
        var iPackage = $(this).data('adowl-select-package');
        $('#adowl-package-'+iPackage).submit();
    });
    
    /* tooltips */
    $('.adowl-tooltip').tooltip({
        placement:'top'
    });
    
    /* skip current step when set */
    if( $('#adowl-skip-step').length > 0 ){
        $('.adowl-save-progress').submit();
    } else {
        /* remove loading state */
        $('.adowl-default').removeClass('adowl-loading');
    }
});